import { useEffect } from "react";
import { useState } from "react";
import { sendGetRequest } from "../../auth";
import { useTranslation } from "react-i18next";
import BackOfficeUserStats from "../BackOfficeUserStats";
import message from "../Alert";

export default function BackOfficeUsers(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [_refresh, setRefresh] = useState(0);
  const { t } = useTranslation();

  function refresh() {
    setRefresh((old) => old + 1);
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const response = await sendGetRequest("/backoffice/");
        setData(response.data.data);
      } catch (error) {
        message.error(t("error.Unknown"));
      }
      setLoading(false);
    }
    loadData();
  }, [_refresh]);

  return (
    <>
      <br />
      <BackOfficeUserStats data={data} refresh={refresh} />
    </>
  );
}
